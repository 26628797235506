import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import CourseParenting from '../components/course/courseParenting';

const Parenting = () => {
  return (
    <>
      <SEO title="Parenting" />
      <Layout>
        <CourseParenting />
      </Layout>
    </>
  );
};

export default Parenting;
