import React from 'react';
import { Row, Col } from 'antd';

import Styles from './course-ind.module.css';
import HeroParenting from '../image-components/hero/heroParenting';
import TabAbout from '../image-components/tab/tabAbout';
import TabOverview from '../image-components/tab/tabOverview';
import TabGuide from '../image-components/tab/tabGuide';

import GuideImg from '../../images/guide-parenting.png';

const CourseParenting = () => {
  return (
    <>
      <HeroParenting />
      <Row className={Styles.row}>
        <Col className={Styles.col_left} sm={24} md={16}>
          <div className={Styles.heading}>PARENTING ADVANCE</div>
          <div className={Styles.body}>
            <div className={Styles.subtitle}>
              <div>Loving our children via the Love of God.</div>
              <p>
                Godly parenting can often feel overwhelming and discouraging.
                That’s why we curated Parenting Advance, where parents can be
                equipped to dream and design a life centered on God.
              </p>
            </div>
          </div>
          <div className={Styles.body}>
            <div className={Styles.quote}>
              "There is nothing more important to consistent, faithful, patient,
              loving, and effective parenting than to understand what God has
              given you in the grace of his Son, the Lord Jesus Christ."
            </div>
            <div className={Styles.author}>PAUL TRIPP</div>
          </div>
        </Col>
        <Col className={Styles.col_right} sm={24} md={8}>
          <TabAbout>
            <div className={Styles.modal_title}>ABOUT</div>
            <div className={Styles.modal_body}>
              <h2 className={Styles.modal_subtitle}>The Learning Outcomes</h2>
              <p className={Styles.subheading}>Advance participants will:</p>
              <ol>
                <li>
                  Participants will gain a biblical framework and understanding
                  of their role as parents.
                </li>
                <li>
                  Participants will design tangible plans to implement in their
                  families that help them center their lives around God.
                </li>
                <li>Participants will unearth their family credos.</li>
              </ol>
              <h2 className={Styles.modal_subtitle}>The Big Idea</h2>
              <p className={Styles.subheading}>Cohort Based</p>
              <p>
                Ekko's Parenting Advance organizes participants to journey
                together through the entire program. In the duration of six
                weeks, participants' primary learning and reflection come from
                their willingness to be vulnerable and open in their stories and
                discoveries to their groups. In so doing, they learn largely
                from each other, walking with other parents in building healthy
                family rhythms in God, ground one another in covenant
                friendship, and give each other new perspective on the calling
                of parenting.
              </p>
              <p className={Styles.subheading}>Advisor-Directed</p>
              <p>
                Parenting Advance is paired with an advisor (Michelle Kim) and
                an assistant who will facilitate the weekly meetings.
              </p>
              <p className={Styles.subheading}>Online & Hybrid</p>
              <p>
                Parenting Advance engages in a variety of venues that are
                EPIC—experiential, participatory, image-driven and connective.
                It does not encourage simply downloading information; instead,
                participants and advisors value relationship and the dynamic
                interaction, which will enrich and deepen its outcome. The
                format will comprise of weekly discussions online.
              </p>
            </div>
          </TabAbout>
          <TabOverview>
            <div className={Styles.modal_title}>OVERVIEW</div>
            <div className={Styles.modal_body}>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 1</div>
                <div className={Styles.subheading}>
                  Gospel Centered Parenting
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 2</div>
                <div className={Styles.subheading}>Family Credo</div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 3</div>
                <div className={Styles.subheading}>Family Values</div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 4</div>
                <div className={Styles.subheading}>Trellis Part I: Sabbath</div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 5</div>
                <div className={Styles.subheading}>
                  Trellis Part II: Prayer & Sabbath Reading/Calendar
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 6</div>
                <div className={Styles.subheading}>Rites of Passage</div>
              </div>
            </div>
          </TabOverview>
          <TabGuide>
            <div className={Styles.modal_title}>GUIDE</div>
            <div className={Styles.modal_body}>
              <img
                className={Styles.guide_img}
                src={GuideImg}
                alt="Pastor Michelle"
              />
              <div className={Styles.guide_name}>Michelle Kim</div>
              <div className={Styles.guide_subheading}>
                <div>Founding Pastor of Ekko Church</div>
                <div>Parenting Advance Guide</div>
              </div>
              <p>
                Pastor Michelle is one of the founding pastors of Ekko Church
                and a member of the Preaching Team. She plays a variety of roles
                in her life, including pastor to Ekko, realtor to those
                searching for a home, friend to many, and mother to her four
                children. Along with her husband, Bryan, she teaches and
                disciples many in the house to love God wholeheartedly and to
                pursue him radically with their obedience.
              </p>
            </div>
          </TabGuide>
        </Col>
      </Row>
    </>
  );
};

export default CourseParenting;
